import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconName } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import {
  OnboardingCheckpointCategory,
  OnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklistV2'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'

export interface OnboardingCheckpointConfig {
  path: string
  title: React.ReactNode
  headerTitle?: React.ReactNode
  label?: string
  category: OnboardingCheckpointCategory
  canView: PermissionTypes[]
  description?: React.ReactNode
  icon: IconName
  globalSetting?: GlobalSettings
  popup: {
    title?: string
    description: string
  }
}

export type OnboardingCheckpointData = {
  data: OnboardingCheckpointInterface
  config: OnboardingCheckpointConfig
}

export const paymentMethodConfig: OnboardingCheckpointConfig = {
  path: ROUTES.PLANS.SELECT_PLAN,
  category: 'paymentMethod',
  title: (
    <FormattedMessage
      id="onboardingV2.paymentMethod.title"
      defaultMessage="Upgrade to a paid plan"
    />
  ),
  label: 'Upgrade to a paid plan',
  canView: [PermissionTypes.ManageSubscriptionPlans],
  icon: 'Cleaning',
  popup: {
    description: '',
  },
}

export const importEmployeesConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
  category: 'importEmployees',
  title: (
    <FormattedMessage
      id="onboardingV2.importEmployees.title"
      defaultMessage="Import employees"
    />
  ),
  label: 'Import employees',
  canView: [PermissionTypes.AddEmployeeUpload],
  icon: 'Upload',
  popup: {
    title: 'Import employees completed',
    description: 'You can always adjust them later again.',
  },
}

export const teamsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.INTRO,
  category: 'teams',
  title: <FormattedMessage id="onboardingV2.teams.title" defaultMessage="Review teams" />,
  label: 'Review teams',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  icon: 'Services',
  popup: {
    description:
      'Teams are set and ready to use. Don’t worry, you can always update it later in Teams app',
  },
}

export const rolesConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.ROLES.INTRO,
  category: 'roles',
  title: <FormattedMessage id="onboardingV2.roles.title" defaultMessage="Review roles" />,
  label: 'Review roles',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  icon: 'RepairTool',
  popup: {
    description:
      'Roles are set and ready to use. Don’t worry, you can always update it later in Organisation app',
  },
}

export const goalsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.INTRO,
  category: 'goals',
  title: <FormattedMessage id="onboardingV2.goals.title" defaultMessage="Setup goals" />,
  label: 'Setup goals',
  description: 'Create goals in the organisation',
  canView: [PermissionTypes.ViewPerformancePreferences],
  icon: 'Target',
  popup: {
    description:
      'Goals are set and ready to use. Don’t worry, you can always update it later in Performance app',
  },
}

export const reviewsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.INTRO,
  category: 'reviews',
  title: (
    <FormattedMessage id="onboardingV2.reviews.title" defaultMessage="Setup reviews" />
  ),
  description: 'Configure your company values and performance scorecard',
  label: 'Setup reviews',
  canView: [PermissionTypes.ViewPerformancePreferences],
  icon: 'StarSemi',
  popup: {
    description:
      'Reviews are set and ready to use. Don’t worry, you can always update it later in Performance app',
  },
}

export const jobsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.INTRO,
  category: 'jobs',
  title: <FormattedMessage id="onboardingV2.jobs.title" defaultMessage="Setup jobs" />,
  label: 'Setup jobs',
  canView: [PermissionTypes.ViewJobPostingPreferences],
  icon: 'AddContact',
  popup: {
    description:
      'Jobs are set and ready to use. Don’t worry, you can always update it later in Job Postings app',
  },
}

export const candidatesConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.INTRO,
  category: 'candidates',
  title: (
    <FormattedMessage
      id="onboardingV2.candidates.title"
      defaultMessage="Setup candidates"
    />
  ),
  label: 'Setup candidates',
  canView: [PermissionTypes.ViewCandidatePreferences],
  icon: 'Profile',
  globalSetting: GlobalSettings.CandidatesEnabled,
  popup: {
    description:
      'Candidates are set and ready to use. Don’t worry, you can always update it later in Candidates app',
  },
}

export const interviewsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.INTRO,
  category: 'interviews',
  title: (
    <FormattedMessage
      id="onboardingV2.interviews.title"
      defaultMessage="Setup interviews"
    />
  ),
  label: 'Setup interviews',
  canView: [PermissionTypes.ViewHiringProcessPreferences],
  icon: 'Chat',
  popup: {
    description:
      'Interviews are set and ready to use. Don’t worry, you can always update it later in Hiring Process app',
  },
}

export const employeeRecordsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
  category: 'employeeRecords',
  title: (
    <FormattedMessage
      id="onboardingV2.employeeRecords.title"
      defaultMessage="Employee records"
    />
  ),
  headerTitle: (
    <FormattedMessage
      id="onboardingV2.employeeRecords.headerTitle"
      defaultMessage="Setup employee records"
    />
  ),
  label: 'Employee records',
  canView: [PermissionTypes.AddEmployeeUpload],
  icon: '16/ListBullet',
  popup: {
    description:
      'Employee records are set and ready to use. Don’t worry, you can always update it later in Employees app',
  },
}

export const timeManagementConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.INTRO,
  category: 'timeManagement',
  title: (
    <FormattedMessage
      id="onboardingV2.timeManagement.title"
      defaultMessage="Time management"
    />
  ),
  headerTitle: (
    <FormattedMessage
      id="onboardingV2.timeManagement.headerTitle"
      defaultMessage="Setup time management"
    />
  ),
  label: 'Time management',
  canView: [
    PermissionTypes.ViewTimeOffPolicies,
    PermissionTypes.ViewTimeOffRegimes,
    PermissionTypes.ViewTimeOffPolicyCategory,
  ],
  icon: 'Resort',
  popup: {
    description:
      'Time management is set and ready to use. Don’t worry, you can always update it later in Time Management app',
  },
}

export const documentsConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.INTRO,
  category: 'documents',
  title: (
    <FormattedMessage id="onboardingV2.documents.title" defaultMessage="Documents" />
  ),
  headerTitle: (
    <FormattedMessage
      id="onboardingV2.documents.headerTitle"
      defaultMessage="Setup documents"
    />
  ),
  label: 'Documents',
  canView: [PermissionTypes.UploadDocuments],
  icon: 'DocumentsPair',
  popup: {
    description:
      'Documents are set and ready to use. Don’t worry, you can always update it later in Documents app',
  },
}

export const payrollConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.INTRO,
  category: 'payroll',
  title: <FormattedMessage id="onboardingV2.payroll.title" defaultMessage="Payroll" />,
  headerTitle: (
    <FormattedMessage
      id="onboardingV2.payroll.headerTitle"
      defaultMessage="Setup payroll reporting"
    />
  ),
  label: 'Payroll',
  canView: [PermissionTypes.ViewPayrollPreferences],
  icon: 'Credit',
  popup: {
    description:
      'Payroll is set and ready to use. Don’t worry, you can always update it later in Payroll app',
  },
}

export const inviteEmployeesConfig: OnboardingCheckpointConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_EMPLOYEES.REVIEW,
  category: 'finish',
  title: (
    <FormattedMessage
      id="onboardingV2.inviteEmployees.title"
      defaultMessage="Invite employees"
    />
  ),
  label: 'Invite employees',
  canView: [PermissionTypes.AddEmployeeUpload],
  icon: 'Chat',
  popup: {
    description: '',
  },
}

export const onboardingCheckpointsSectionsConfig = {
  firstSteps: {
    title: 'Your first steps',
    configs: [paymentMethodConfig, importEmployeesConfig, teamsConfig, rolesConfig],
  },
  performance: {
    title: 'Setup Performance',
    configs: [goalsConfig, reviewsConfig],
  },
  recruitment: {
    title: 'Setup Recruitment',
    configs: [jobsConfig, candidatesConfig, interviewsConfig],
  },
  hr: {
    title: 'HR Onboarding',
    configs: [
      employeeRecordsConfig,
      timeManagementConfig,
      documentsConfig,
      payrollConfig,
    ],
  },
  launch: {
    title: 'Launch',
    configs: [inviteEmployeesConfig],
  },
}

export const allSortedConfigs = Object.values(onboardingCheckpointsSectionsConfig)
  .map(section => section.configs)
  .flat()
